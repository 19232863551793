import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import SchoolIcon from "@mui/icons-material/School";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import ScienceIcon from "@mui/icons-material/Science";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import TimelineIcon from "@mui/icons-material/Timeline";

export default function Education() {
  const data = [
    {
      year: "2018 - 2019",
      description:
        "I graduated from High School and Started my journey as a web developer. I started with HTML, CSS and some JavaScript. I was able to create some simple websites and web apps.",
      color: "#00bcd4",
      icon: <SchoolIcon />,
      className: "hiddenRight",
    },
    {
      year: "2019 - 2020",
      description: `I was a student at the University of Sciences Semlalia in Marrakech, studying Physics and Chemistry. I had begun learning programming languages such as Python, C, and C#.`,
      color: "#81d39a",
      icon: <ScienceIcon />,
      className: "hiddenLeft",
    },
    {
      year: "2020 - 2022",
      description:
        "I developed some projects using React, Node.js, and Django as a backend framework. I also learned about some other technologies like MySQL, and Firebase.",
      color: "hwb(207deg 56% 2%)",
      icon: <LaptopMacIcon sx={{ color: "black" }} />,
      className: "hiddenRight",
    },
    {
      year: "2022 - 2023",
      description:
        "After leaving college, I started my journey at the Moroccan School of Engineering Sciences (EMSI). I am currently a second-year student in the field of computer science.",
      color: "#f9a825",
      icon: <RestartAltIcon />,
      className: "hiddenLeft",
    },
  ];


  const h1_Style = {
    fontFamily: "Outfit, sans-serif",
    fontWeight: "400",
    fontSize: "18px",
    clear: "both",
    whiteSpace: "nowrap",
    "@media (min-width: 600px)": {
      fontSize: "12px",
    },
  };

 
  return (
    <React.Fragment>
      <Grid container id="Education">
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            textAlign: "center",
          }}
        >
          <h3>
            Education
          </h3>
        </Grid>


        <Grid item xs={12}>
          <Timeline
            position="alternate"
            sx={{
              position: "relative",
              margin: "0",
              fontFamily: "Raleway, serif !important",
              color: "black",
            }}
          >
            {data.map((item, index) => {
              return (
                <TimelineItem key={index} className={item.className}>
                  <TimelineSeparator>
                    <TimelineDot sx={{ backgroundColor: item.color }}>
                      {item.icon}
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ color: "black" }}>
                    <Typography
                      variant="h6"
                      component="h1"
                      sx={{h1_Style}}
                    >
                      {item.year}
                    </Typography>
                    <Typography
                      variant="caption"
                      id="description"
                    >
                      {item.description}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
            <TimelineItem className="hiddenRight">
              <TimelineSeparator>
                <TimelineDot sx={{ backgroundColor: "#81d39a" }}>
                  <TimelineIcon sx={{ color: "black" }} />
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent sx={{ color: "black" }}>
                <Typography
                  variant="h6"
                  component="h1"
                  sx={{h1_Style}}>
                  2022 - 2027
                </Typography>
                <Typography
                  variant="caption"
                  id="description"
                >
                 This is the future, and while I cannot predict its exact course, I hold onto the hope that it will bring forth positive outcomes.
                </Typography>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
