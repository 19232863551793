import React from "react";
import avatar from "../assets/avatar.svg";
import "../assets/css/whoami.css";
import Grid from "@mui/material/Grid";
import { Typography, Avatar } from "@mui/material";
import Typed from "react-typed";

const TypographyStyled = {
  my: 2,
  fontFamily: "Outfit, sans-serif",
  fontSize: "50px",
  fontWeight: "700",
  lineHeight: "110%",
  textTransform: "capitalize",
};

export default function MyName(props) {
  return (
    <React.Fragment>
      <Grid
        id="home"
        container
        sx={{
          position: "relative",
          top: "60px",
          margin: "40px 0px",
          display: "flex",
          flexDirection: "row-reverse",
          flexWrap: "wrap",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          className="animate__animated animate__backInLeft"
        >
          <Typography component={"span"} sx={TypographyStyled}>
            Hi There!
            <span className="wave">👋🏻</span>
            <Typography
              component={"h1"}
              sx={{ ...TypographyStyled, fontSize: "30px" }}
            >
              I'M{"\u00a0"}
              <span style={{ color: "#6a61fa" }}>{props.fullName.toUpperCase()}</span>
              <div style={{ color: "#6a61fa", fontSize: "18px" }}>
                <Typed
                  strings={props.job}
                  typeSpeed={40}
                  backSpeed={50}
                  loop
                />
              </div>
            </Typography>
            <Typography
              className="animate__animated animate__backInLeft"
              variant="h6"
              sx={{
                fontSize: "16px",
                fontFamily: "ubuntu, sans-serif",
                padding: "0px 15px 0px 0px",
                textAlign: "left",
              }}
            >
              I am a passionate software developer, currently based in
              Marrakech. I have a strong background in software engineering,
              having worked on a variety of projects in the past. My goal is to
              find a web development position where I can use my skills and
              experience to create beautiful and user-friendly interfaces that
              provide real solutions to people's problems
            </Typography>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="animate__animated animate__backInRight"
        >
          <Avatar
            sx={{ width: 256, height: 256 }}
            src={avatar}
            alt="Yassine Amjad"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
