import * as React from "react";
import { AppBar } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Brightness4Icon from '@mui/icons-material/Brightness4';

import { Link } from "react-scroll";

const drawerWidth = 200;
const navTabs = [
  {
    route: "home",
    name: "Home",
  },
  {
    route: "about",
    name: "About",
  },
  {
    route: "experience",
    name: "Experience",
  },
  {
    route: "Education",
    name: "Education",
  },
  {
    route: "projects",
    name: "Project",
  },
];

const navTabsStyle = {
  desktop: {
    color: "#6a61fa",
    fontWeight: "bold",
    fontFamily: "Outfit, sans-serif",
    margin: "0 0.5rem",
  },
  mobile: {
    fontWeight: "bold",
    fontFamily: "Outfit, sans-serif",
    height: "4rem",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    spaceBetween: "100px",
    color: "#6a61fa",
  },
};

export default function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      className=""
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
      }}
    >
      <Typography variant="h6" sx={{ my: 2, color: "#6a61fa" }}>
        YA.
      </Typography>
      <Divider />
      <List>
        {navTabs.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              id="NavButton"
              sx={navTabsStyle.mobile}
            >
              <Link
                activeClass="active"
                to={item.route}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                {item.name}
              </Link>
            </ListItemButton>
          </ListItem>
        ))}

      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar component="nav" color="transparent" className="Nav">
        <Toolbar>
          <MenuIcon
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{
              mr: 2,
              display: { sm: "none" },
              color: "#6a61fa",
            }}
          >
            <MenuOpenIcon />
          </MenuIcon>

          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block", color: "#6a61fa" },
            }}
          >
            YA.
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navTabs.map((item, index) => (
              <Button
                id="NavButton"
                key={index}
                sx={navTabsStyle.desktop}
              >
                <Link
                  activeClass="active"
                  to={item.route}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  {item.name}
                </Link>
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
