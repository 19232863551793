import "../assets/css/aboutMe.css";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";
import homePic from "../assets/coding-person.svg";


function AboutMe() {
  const TyppographyStyle = {
    fontFamily: "Outfit, sans-serif",
    fontSize: "30px",
    fontWeight: "700",
    lineHeight: "110%",
    textTransform: "capitalize",
    textAlign: "center",
  };

  return (
    <React.Fragment>
      <Grid container id="about">
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            margin: "40px 0px",
          }}
          className="about-me-container"
        >
          <Typography
            variant="h1"
            className="about-me-title animate__animated animate__backInLeft"
            sx={{
              ...TyppographyStyle,
              fontSize: "50px",
              textAlign: "left",
            }}
          >
            know me better than{"\u00a0"}
            <span className="highlight-container">
              <span className="highlight">anyone</span>
            </span>
            {"\u00a0"}else
          </Typography>

          <Typography
            variant="h6"
            className="animate__animated animate__backInLeft"
            sx={{
              fontSize: "16px",
              fontFamily: "ubuntu, sans-serif",
              padding: "15px 0px",
              textAlign: "left",
            }}
          >
          I Am Deeply Passionate About Utilizing Web Development To Create Solutions That Can Benefit And Help Others. Through My Self-Taught Journey In The Field, I Have Honed My Skills And Am Continually Seeking New Technologies To Learn And Master. In Addition To My Passion For Web Development, I Also Enjoy Indulging In My Hobbies Such As Playing Video Games And Watching Movies.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
          className="animate__animated animate__backInRight"
        >
          <Box
            component="img"
            sx={{
              maxHeight: { xs: 450, md: 350 },
              maxWidth: { xs: 450, md: 350 },
              width: "100%",
              height: "100%",
            }}
            alt="The house from the offer."
            src={homePic}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default AboutMe;
